/* Provide sufficient contrast against white background */
body {
  font-family: "Roboto", sans-serif;
}

a {
  color: #40bac5;
  font-weight: 500;
}

a:hover {
  color: #2c8d94;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #40bac5;
  border-color: #40bac5;
}

.padding-before {
  padding-top: 20px;
}

.page-link {
  color: #40bac5;
}

.page-item.active .page-link {
  background-color: #40bac5;
  color: #fff;
}

.right-align {
  text-align: right;
}

.invoice-heading {
  font-weight: 500;
}

.invoice-table-row {
  margin-top: 20px;
  margin-bottom: 20px;
}

h1 {
  margin-bottom: 20px;
}

.table td.handpiece-status-col {
  padding: 0.75em 0 0.75em 0.75em;
}

.table td.button-cell {
  padding: 0.5em;
  text-align: right;
}

.active-link {
  color: black;
}

.print {
  font-size: 20px;
}

.print-border {
  border: 1px solid black;
  padding-bottom:10px;
}

.print-header {
  font-weight: bold;
  border-bottom: 1px solid black;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.print-check-box {
  height: 16px;
  width: 16px;
  margin: 2px 400px 2px 2px;
  border: 1px solid darkgray;
  float: right;
}

.print-dotted-line {
  width: 100%;
  display: flex;
}

.print-dotted-line .dot {
  flex: 1;
  border-bottom: 1px dotted darkgray;
  height: 1.2em;
  margin-left: 10px;
}

.print-check-box-pane {
  font-size: 24px;
  line-height: 36px;
}

.print-check-box-big {
  height: 20px;
  width: 20px;
  border: 1px solid darkgray;
  float: left;
  margin-top: 6px;
  margin-right: 6px;
}

.print-big-line-height {
  line-height: 36px;
}

.print-handpiece-row {
  border-bottom: 1px solid darkgray;
}

.print-handpiece-table {
  margin: 0 2px;
}

.print-handpiece-table th {
  border-top: none;
}

.print-handpiece-table tr:last-child {
  border-bottom: none;
}

@media print {
  .no-print, .no-print *
  {
    display: none !important;
  }
  .print-only, .print-only * {
    display: initial;
  }

  /*body {*/
  /*  color: #2c8d94;*/
  /*}*/

}

