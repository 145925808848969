h3 {
    font-size: 18px;
    font-weight: 500;
}

a.social-icon {
    display: inline-block;
    text-align: center;
    color: white;
    background-color: #40bac5;
    border-radius: 10%;
    font-size: 25px;
    padding: .5em;
    margin-right: 5px;
    cursor: pointer;
}

a.social-icon:hover {
    color: white;
    text-decoration: none;
}

a.social-icon i {
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
}

.footer {
    margin-top: 20px;
    padding: 40px 0;
    border-top: 1px solid #e7e7e7;
}

.footer-col {
    margin-bottom: 20px;
}

.footer-col p {
    margin-bottom: 0;
}
